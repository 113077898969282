import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faCircleDollarToSlot,
  faPrayingHands,
} from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { AboutUsComponent } from '../../components/about-us/about-us.component';
import { AboutComponent } from '../../components/about/about.component';
import { AdoptionProcessComponent } from '../../components/adoption-process/adoption-process.component';
import { DisruptionsComponent } from '../../components/disruptions/disruptions.component';
import { HeroComponent } from '../../components/hero/hero.component';
import { PrayerFormComponent } from '../../components/prayer-form/prayer-form.component';

@Component({
  selector: 'app-home-page',
  imports: [
    HeroComponent,
    AboutComponent,
    AdoptionProcessComponent,
    DisruptionsComponent,
    FaIconComponent,
    AboutUsComponent,
  ],
  providers: [BsModalService],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HomePageComponent implements OnInit {
  public readonly faCircleDollarToSlot = faCircleDollarToSlot;
  public readonly faPrayingHands = faPrayingHands;

  private _meta = inject(Meta);
  private _prayerModal: BsModalRef<PrayerFormComponent> | undefined = undefined;
  private _modalService = inject(BsModalService);

  public ngOnInit(): void {
    this._meta.addTags([
      {
        name: 'description',
        content:
          'Help us adopt by donating or by sending a prayer to an expectant mother. While here learn about adoption process and how you can best support us.',
      },
      {
        name: 'keywords',
        content:
          'Adoption, Domestic Infant Adoption, Adoptee, Advocacy, Donation, Prayer, Adoption Process, Beveridge',
      },
    ]);
  }

  public openPrayerModal(): void {
    // Angular SSR can have click events rendered twice!!! WTF
    if (!this._prayerModal) {
      this._prayerModal = this._modalService.show(PrayerFormComponent, {
        class: 'modal-dialog-scrollable',
        backdrop: 'static',
      });
      this._prayerModal.onHidden?.pipe(take(1)).subscribe(() => {
        this._prayerModal = undefined;
      });
    }
  }
}
