import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppSettings {
  public siteName = 'Adopt Bean';
  public appTheme = '';
  public appCover = '';
  public appDarkMode = false;
  public appEmpty = true;
  public appGradientEnabled = false;
  public appBodyWhite = false;
  public appThemePanelNone = false;

  public appBoxedLayout = false;
  public appHeaderNone = false;
  public appHeaderFixed = true;
  public appHeaderInverse = false;
  public appHeaderMegaMenu = false;
  public appHeaderLanguageBar = false;
  public appHeaderMegaMenuMobileToggled = false;
  public appTopMenu = false;
  public appFooter = false;

  public appSidebarEnd = false;
  public appSidebarTwo = false;
  public appSidebarNone = true;
  public appSidebarGrid = false;
  public appSidebarWide = false;
  public appSidebarLight = false;
  public appSidebarFixed = false;
  public appSidebarSearch = false;
  public appSidebarMinified = false;
  public appSidebarCollapsed = false;
  public appSidebarTransparent = false;
  public appSidebarMobileToggled = false;
  public appSidebarRightCollapsed = false;
  public appSidebarEndToggled = false;
  public appSidebarEndMobileToggled = false;

  public appContentClass = '';
  public appContentFullHeight = false;
  public appContentFullWidth = false;
}
