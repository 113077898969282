import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-disruptions',
  imports: [NgOptimizedImage],
  templateUrl: './disruptions.component.html',
  standalone: true,
  styleUrl: './disruptions.component.css',
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DisruptionsComponent {}
