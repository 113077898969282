<!-- BEGIN #home -->
<div
  id="home"
  class="py-5 position-relative bg-black bg-size-cover mt-5"
  data-bs-theme="dark"
>
  <!-- BEGIN container -->
  <div class="container-xxl p-3 p-lg-5">
    <!-- BEGIN div-hero-content -->
    <div class="div-hero-content z-3 position-relative">
      <!-- BEGIN row -->
      <div class="row">
        <!-- BEGIN col-8 -->
        <div class="col-lg-6">
          <!-- BEGIN hero-title-desc -->
          <h1 class="display-6 fw-light mb-2 mt-4 text-white">
            Be Part of Our
            <span class="fw-bold border-bottom">Adoption Story</span>
          </h1>
          <div class="fs-18px text-body text-opacity-75 mb-4">
            A journey of love and new beginnings. Your prayers for our
            <span class="d-xl-inline d-none"><br /></span>
            expectant mother and her baby, along with your generous
            <span class="d-xl-inline d-none"><br /></span>
            donations, help pave the path toward a forever family.
            <span class="d-xl-inline d-none"><br /></span>
            Thank you for sharing in our dream.
          </div>
          <!-- END hero-title-desc -->
          <div class="mb-2">
            <ng-content />
          </div>
          <!-- END row -->
        </div>
        <!-- END col-8 -->
      </div>
      <!-- END row -->
    </div>
    <!-- END div-hero-content -->
  </div>
  <!-- END container -->
  <div
    class="position-absolute bg-size-cover bg-position-center bg-no-repeat top-0 start-0 w-100 h-100"
    style="background-image: url(/img/hero.jpg)"
  ></div>
  <div
    class="position-absolute top-0 start-0 d-none2 w-100 h-100 bg-gray-900 bg-opacity-80"
  ></div>
</div>
<!-- END #home -->
