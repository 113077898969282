<div id="adoption-process" class="py-5">
  <div class="container-xxl p-3 p-lg-5">
    <div class="col-xl-12">
      <h1 class="mb-3 text-center">Steps in the Adoption Process</h1>
      <p class="fs-16px text-body">
        Pursuing a domestic infant adoption is a long process that contains a
        series of steps and sub-steps. It takes several months to become what
        many adoption agencies consider "match ready".
      </p>
      <p class="fs-16px text-body">
        Below you can see the steps in the adoption process and see our
        progress.
      </p>
      <div class="accordion" id="adoptionProcessAccordion">
        <!-- Agency Selection -->
        <div class="accordion-item border-0">
          <div class="accordion-header" id="agencySelection">
            <button
              class="accordion-button bg-white px-3 py-10px pointer-cursor"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseAgencySelection"
            >
              1 - Agency Selection
            </button>
          </div>
          <div
            id="collapseAgencySelection"
            class="accordion-collapse collapse show"
            data-bs-parent="#adoptionProcessAccordion"
          >
            <div class="accordion-body bg-white">
              An agency helps navigate you through the adoption process. The
              agency will be present from the initial steps of the home study
              all the way through finalization. The agency will help setup
              matches between expectant mothers and the agencies clients. They
              will provide the information collected during the home study to
              the necessary parties when a birth mother chooses to place her
              baby with you. Any questions you have throughout the process can
              also be directed to the agency. The agency may have requirements
              of their own that go beyond what is required in the home study.
            </div>
          </div>
        </div>
        <!-- Home Study & Eduction -->
        <div class="accordion-item border-0">
          <div class="accordion-header" id="homeStudy">
            <button
              class="accordion-button bg-white px-3 py-10px pointer-cursor collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseHomeStudy"
            >
              2 - Home Study & Education
            </button>
          </div>
          <div
            id="collapseHomeStudy"
            class="accordion-collapse collapse"
            data-bs-parent="#adoptionProcessAccordion"
          >
            <div class="accordion-body bg-white">
              <p>
                The Home Study and education process can take several weeks to
                months to complete. It ensures that you and your home will
                provide a safe environment for a child. The paperwork includes
                all necessary documents needed during the legal process.
              </p>
              <ul
                class="columns-1 md:columns-2 lg:columns-3 gap-4 space-y-2 list-none"
              >
                <li>Identity Documents</li>
                <li>Guardianship Forms</li>
                <li>Adoption Preferences*</li>
                <li>Federal, State and Local Background Checks</li>
                <li>
                  History (Address History, Marriage Certificate, Autobiography)
                </li>
                <li>Proof of Insurance Medical, Auto, Home, Life</li>
                <li>
                  Financial Statements (Bank Statements, Pay Stubs, W2s,
                  Mortgage Statements, Financial Affidavit, Other Debts, etc.)
                </li>
                <li>Disclosures (Weapons, Therapist Release, etc.)</li>
                <li>Pet Vaccinations & Safety Plan</li>
                <li>20+ Hours of Education and CPR Certification</li>
                <li>Individual and Couple Interviews</li>
                <li>Home Tour/Safety Check</li>
              </ul>
              <p>
                *The Adoption Preferences worksheet requires careful
                consideration to know what you can and cannot support for any
                child that you plan to adopt. A child's potential health
                complications could be something to discern. Or you may live in
                an area that wouldn't allow a child to experience their culture.
                Regardless of the circumstances, it is important to remember
                that adoption is child focused and it would be a disservice to
                the child to not provide them with the best possible
                opportunities.
              </p>
            </div>
          </div>
        </div>
        <!-- Adoption Profile -->
        <div class="accordion-item border-0">
          <div class="accordion-header" id="adoptionProfile">
            <button
              class="accordion-button bg-white px-3 py-10px pointer-cursor collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseAdoptionProfile"
            >
              3 - Adoption Profile
              <span class="ms-2 text-red-500">[IN PROGRESS]</span>
            </button>
          </div>
          <div
            id="collapseAdoptionProfile"
            class="accordion-collapse collapse"
            data-bs-parent="#adoptionProcessAccordion"
          >
            <div class="accordion-body bg-white">
              An adoption profile helps an expectant mother see a glimpse of
              what life for their child would look like. An expectant mother
              needs to know that if she decides to place her child with you that
              they will be in good hands. An adoption profile shows the
              expectant mother what your home environment looks like (do you
              live in the city or in the country-side), what hobbies/interests
              you have and what day-to-day could look like for their child.
              Typically prospective adoptive parents create a profile book to
              showcase their lives.
            </div>
          </div>
        </div>
        <!-- Matching Phase -->
        <div class="accordion-item border-0">
          <div class="accordion-header" id="matchingPhase">
            <button
              class="accordion-button bg-white px-3 py-10px pointer-cursor collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseMatchingPhase"
            >
              4 - Matching Phase
            </button>
          </div>
          <div
            id="collapseMatchingPhase"
            class="accordion-collapse collapse"
            data-bs-parent="#adoptionProcessAccordion"
          >
            <div class="accordion-body bg-white">
              <p>
                During the matching phase, we are presented with cases and asked
                if we would like to share our profile with an expectant mother.
                The amount of information given to us will be limited and we
                will have a short timeframe to decide whether or not we want to
                share our adoption profile with the expectant mother. This phase
                will generally occur during the expectant mother's 3rd
                trimester. If we are selected by the expectant mother as
                prospective parents we will become acquainted with each other.
              </p>
            </div>
          </div>
        </div>
        <!-- Placement -->
        <div class="accordion-item border-0">
          <div class="accordion-header" id="placement">
            <button
              class="accordion-button bg-white px-3 py-10px pointer-cursor collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsePlacement"
            >
              5 - Placement
            </button>
          </div>
          <div
            id="collapsePlacement"
            class="accordion-collapse collapse"
            data-bs-parent="#adoptionProcessAccordion"
          >
            <div class="accordion-body bg-white">
              <p>
                Placement is when the birth mother signs over parental rights,
                indicating that she trusts us with raising her child. While on
                the one end we will be over-joyed to have a new member of our
                family, we expect that it will be difficult for the birth
                mother. Every part of her body will communicate that she just
                gave birth, but she will not be bringing a child home.
              </p>
              <p>
                Throughout this process we think it is important to remember the
                birth mother and make sure that she knows that she is not alone.
                While we will be adding a baby as a new member to our family, we
                will also welcome the birth mother so that she can be present in
                her child's life. Placement needs to be a good fit in which the
                child can be supported, but also be open to have a relationship
                with their birth mother.
              </p>
            </div>
          </div>
        </div>
        <!-- Post-Placement -->
        <div class="accordion-item border-0">
          <div class="accordion-header" id="postPlacement">
            <button
              class="accordion-button bg-white px-3 py-10px pointer-cursor collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsePostPlacement"
            >
              6 - Post-Placement
            </button>
          </div>
          <div
            id="collapsePostPlacement"
            class="accordion-collapse collapse"
            data-bs-parent="#adoptionProcessAccordion"
          >
            <div class="accordion-body bg-white">
              <p>
                Post-placement is an exciting time and there are a lot of
                firsts. All of these little moments will be absolutely precious
                and we look forward to seeing them and sharing them with the
                community that has helped support us. During this time, social
                workers will check in and make sure that the baby is in a loving
                home and that all of their needs can be provided.
              </p>
              <p>
                Additionally, we will navigate openness with the birth mother if
                she is willing. That will include ensuring time together for the
                birth mother and baby, sharing photos/videos, and continuing to
                develop a life-long relationship between families. Ultimately,
                everything we do during this time can help a child to experience
                love from both families.
              </p>
            </div>
          </div>
        </div>
        <!-- Finalization -->
        <div class="accordion-item border-0">
          <div class="accordion-header" id="finalization">
            <button
              class="accordion-button bg-white px-3 py-10px pointer-cursor collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFinalization"
            >
              7 - Finalization
            </button>
          </div>
          <div
            id="collapseFinalization"
            class="accordion-collapse collapse"
            data-bs-parent="#adoptionProcessAccordion"
          >
            <div class="accordion-body bg-white">
              <p>
                After post-placement finalization occurs. Finalization is a
                legal process that establishes us (the adoptive parents) as
                legal guardians of the child. While we are considered the legal
                guardians of the child, we look forward to having an open
                adoption so that the birth mother can be present in the child's
                life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
