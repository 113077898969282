<app-hero>
  <a
    href="https://adopttogether.org/families/the-beveridges"
    target="_blank"
    class="btn btn-lg btn-theme px-3"
  >
    <fa-icon [icon]="faCircleDollarToSlot" />
    Donate Today
  </a>
  <button
    type="button"
    class="ms-2 btn btn-lg btn-default px-3"
    (click)="openPrayerModal()"
  >
    <fa-icon [icon]="faPrayingHands" />
    Send a Prayer
  </button>
</app-hero>
<app-about-us />
<app-about />
<app-adoption-process />
<app-disruptions />
