import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  imports: [],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
  standalone: true,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AboutComponent {}
