import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatePrayerDto } from '../../dtos/create-prayer.dto';
import { Prayer } from '../../dtos/prayer.dto';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrayerService {
  private _http: HttpClient = inject(HttpClient);

  public sendPrayer(dto: CreatePrayerDto): Observable<Prayer> {
    return this._http.post<Prayer>(
      `${environment.apiGatewayHost}/api/prayers`,
      dto,
    );
  }
}
