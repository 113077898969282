import { isPlatformBrowser, NgClass } from '@angular/common';
import {
  Component,
  HostListener,
  inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { AppSettings } from './service/app-settings.service';
import { AppVariablesService } from './service/app-variables.service';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, NgClass, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
})
export class AppComponent implements OnInit {
  public title = 'Little Junebug';
  public appSettings = inject(AppSettings);
  public appHasScroll = false;
  private _router = inject(Router);
  private _platformId = inject(PLATFORM_ID);
  private _appVariablesManager = inject(AppVariablesService);
  public appVariables!: object;

  public ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.appVariables = this._appVariablesManager.getAppVariables();
    }
  }

  constructor() {
    this._router.events.subscribe((e) => {
      if (isPlatformBrowser(this._platformId) && e instanceof NavigationStart) {
        if (window.innerWidth < 768) {
          this.appSettings.appSidebarMobileToggled = false;
          this.appSettings.appSidebarEndMobileToggled = false;
        }
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(): void {
    const doc = document.documentElement;
    const top = (window.scrollY || doc.scrollTop) - (doc.clientTop || 0);
    this.appHasScroll = top > 0 && this.appSettings.appHeaderFixed;
  }

  // set page minified
  public onAppSidebarMinifiedToggled(): void {
    this.appSettings.appSidebarMinified = !this.appSettings.appSidebarMinified;
    localStorage['appSidebarMinified'] = this.appSettings.appSidebarMinified;
  }

  public onAppSidebarEndToggled(): void {
    this.appSettings.appSidebarEndToggled =
      !this.appSettings.appSidebarEndToggled;
  }

  public onAppSidebarMobileToggled(): void {
    this.appSettings.appSidebarMobileToggled =
      !this.appSettings.appSidebarMobileToggled;
  }

  public onAppSidebarEndMobileToggled(): void {
    this.appSettings.appSidebarEndMobileToggled =
      !this.appSettings.appSidebarEndMobileToggled;
  }

  public onAppDarkModeChanged(): void {
    if (this.appSettings.appDarkMode) {
      document.documentElement.setAttribute('data-bs-theme', 'dark');
    } else {
      document.documentElement.removeAttribute('data-bs-theme');
    }
    this.appVariables = this._appVariablesManager.getAppVariables();
    this._appVariablesManager.variablesReload.emit();
    document.dispatchEvent(new CustomEvent('theme-change'));
  }

  public onAppThemeChanged(): void {
    /*
    const newTheme = 'theme-' + this.appSettings.appTheme;
    for (const className of document.body.classList) {
      if (className.includes('theme-') && className !== newTheme) {
        document.body.classList.remove(className);
      }
    }
    document.body.classList.add(newTheme);
    this.appVariables = this._appVariablesManager.getAppVariables();
    this._appVariablesManager.variablesReload.emit();
    */
  }
}
