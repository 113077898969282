import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';

export const routes: Routes = [
  { title: 'Little Junebug | Home', path: '', component: HomePageComponent },
  /*{
    title: 'Little Junebug| Login',
    path: 'login',
    component: LoginPageComponent,
  },
  {
    title: 'Little Junebug| Register',
    path: 'register',
    component: RegisterPageComponent
  },
  {
    title: 'Little Junebug| Gallery',
    path: 'gallery',
    component: GalleryPageComponent
  },*/
  {
    title: 'Little Junebug | Not Found',
    path: '**',
    component: NotFoundPageComponent,
  },
];
