<!-- BEGIN #about -->
<div id="about" class="py-5 bg-component">
  <div class="container-xxl p-3 p-lg-5 text-start">
    <h1 class="mb-3 text-center">How Can I Help?</h1>
    <p class="fs-16px text-body text-opacity-50">
      Our job as prospective adoptive parents is to be an advocate for our
      future child. Below you will find some information on the adoption process
      and what can be expected. If you are able to, please consider donating to
      our
      <a
        href="https://adopttogether.org/families/the-beveridges"
        target="_blank"
      >
        Adopt Together
      </a>
      fundraiser. Additionally, please pray for the expectant mother. We greatly
      appreciate any help you can provide.
    </p>
    <p class="fs-16px text-body text-opacity-50">
      For more information on how you can support us, please check out this
      <a
        href="https://absoluteloveadoptions.com/blog/how-to-be-a-good-friend-to-someone-who-is-adopting/"
        target="_blank"
      >
        blog article
      </a>
      by Kathryn Russell.
    </p>
    <p class="fs-16px text-body text-opacity-50">
      We look forward to being able to share updates with the community on our
      adoption journey!
    </p>
  </div>
</div>
<!-- END #about -->
