import { Component, OnDestroy, inject, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight, faBars } from '@fortawesome/free-solid-svg-icons';
import { AppSettings } from '../../service/app-settings.service';
import { MenuItemInterface } from './menu-item.interface';

declare let slideToggle: never;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  imports: [FaIconComponent, RouterLink],
  standalone: true,
})
export class HeaderComponent implements OnDestroy {
  public readonly appSidebarTwo$ = input.required<boolean>();
  public readonly menuItems$ = input<MenuItemInterface[]>([
    { label: 'Home', path: '/' },
  ]);
  public readonly appSidebarEndToggled = output<boolean>();
  public readonly appSidebarMobileToggled = output<boolean>();
  public readonly appSidebarEndMobileToggled = output<boolean>();
  public appSettings: AppSettings = inject(AppSettings);
  public readonly faBars = faBars;
  public readonly faArrowRight = faArrowRight;

  public toggleAppSidebarMobile(): void {
    this.appSidebarMobileToggled.emit(true);
  }

  public toggleAppSidebarEnd(): void {
    this.appSidebarEndToggled.emit(true);
  }

  public toggleAppSidebarEndMobile(): void {
    this.appSidebarEndMobileToggled.emit(true);
  }

  public toggleAppTopMenuMobile(): void {
    const target = document.querySelector('.app-top-menu');
    if (target) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      slideToggle(target);
    }
  }

  public toggleAppHeaderMegaMenuMobile() {
    this.appSettings.appHeaderMegaMenuMobileToggled =
      !this.appSettings.appHeaderMegaMenuMobileToggled;
  }

  public ngOnDestroy() {
    this.appSettings.appHeaderMegaMenuMobileToggled = false;
  }
}
