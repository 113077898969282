<div id="adoption-process" class="py-5">
  <div class="container-xxl py-3 py-lg-5">
    <h1 class="mb-4 text-center">Welcome</h1>

    <div class="d-flex flex-column flex-md-row mx-5">
      <!-- Image Column -->
      <div
        class="card border-0 mb-4 mb-md-0 me-md-4 text-center align-self-center"
        style="min-width: 300px; width: 300px"
      >
        <img
          ngSrc="/img/family.jpg"
          height="300"
          width="300"
          alt="Joanna, River, Joseph and Scout"
          class="img-fluid rounded"
          style="height: 300px; width: 300px; object-fit: cover"
        />
        <div class="card-body">
          <h4 class="card-title mb-2">Our Family</h4>
          <p class="card-text">Joanna, Joseph, River &amp; Scout</p>
        </div>
      </div>

      <!-- Text Column -->
      <div class="text-center text-md-start">
        <p class="fs-16px text-body">
          We’re Joe and Joanna! We have been married for 9 years and we are
          truly best friends! Our faith and family is at the heart of everything
          we do. We plan most of our days around having a good cup of coffee,
          walking our dogs, and exercising. After a long journey with
          infertility, we truly feel called to the unique experience of
          adoption.
        </p>
        <p class="fs-16px text-body">
          Please take a minute to read through our website and learn more about
          the adoption process. Additionally, we would welcome any prayers and
          you can even leave a prayer for the expectant mother.
        </p>
      </div>
    </div>
  </div>
</div>
