import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSwitcheryModule } from '@csiro-geoanalytics/angular-switchery-ios';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faPaperPlane,
  faPrayingHands,
  faSpinner,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  OptionalFieldContainerComponent,
  ValidatorDirective,
} from '@little-junebug/common-ui';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CreatePrayerDto } from '../../../dtos/create-prayer.dto';
import { CreatePrayerFormInterface } from '../../../forms/create-prayer-form.interface';
import { PrayerService } from '../../service/prayer.service';

@Component({
  selector: 'app-prayer-form',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FaIconComponent,
    OptionalFieldContainerComponent,
    ValidatorDirective,
    NgSwitcheryModule,
    AsyncPipe,
  ],
  host: { ngSkipHydration: 'true' },
  templateUrl: './prayer-form.component.html',
  styleUrl: './prayer-form.component.scss',
  standalone: true,
})
export class PrayerFormComponent implements OnInit, OnDestroy {
  // Icons
  public readonly faPrayingHands = faPrayingHands;
  public readonly faPaperPlane = faPaperPlane;
  public readonly faSpinner = faSpinner;
  public readonly faXmark = faXmark;
  private readonly _prayerService = inject(PrayerService);
  private readonly _modalRef = inject(BsModalRef);

  private readonly _destroy$ = new Subject<void>();

  private readonly _isSharingName$ = new BehaviorSubject<boolean>(false);
  private readonly _isSharingEmail$ = new BehaviorSubject<boolean>(false);
  private readonly _isSharingMessage$ = new BehaviorSubject<boolean>(false);

  public isSharingName$ = this._isSharingName$.asObservable();
  public isSharingEmail$ = this._isSharingEmail$.asObservable();
  public isSharingMessage$ = this._isSharingMessage$.asObservable();

  public readonly isSubmittingForm = signal(false);
  public form = new FormGroup<CreatePrayerFormInterface>({
    PrayerText: new FormControl('', {
      validators: [
        // eslint-disable-next-line @typescript-eslint/unbound-method
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(2000),
      ],
      nonNullable: true,
    }),
    PrayerTitle: new FormControl('', {
      validators: [],
      nonNullable: true,
    }),
    Name: new FormControl('', {
      validators: [],
      nonNullable: true,
    }),
    Email: new FormControl('', {
      validators: [],
      nonNullable: true,
    }),
    Comments: new FormControl('', {
      validators: [],
      nonNullable: true,
    }),
  });

  public ngOnInit(): void {
    // Initialize the subscription for Name toggle
    this._isSharingName$
      .pipe(takeUntil(this._destroy$))
      .subscribe((isSharing) => {
        const nameControl = this.form.get('Name');
        if (nameControl) {
          if (isSharing) {
            nameControl.setValidators([
              // eslint-disable-next-line @typescript-eslint/unbound-method
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(75),
            ]);
          } else {
            nameControl.clearValidators();
            nameControl.reset();
          }
          nameControl.updateValueAndValidity();
        }
      });

    // Initialize the subscription for Email toggle
    this._isSharingEmail$
      .pipe(takeUntil(this._destroy$))
      .subscribe((isSharing) => {
        const emailControl = this.form.get('Email');
        if (emailControl) {
          if (isSharing) {
            emailControl.setValidators([
              // eslint-disable-next-line @typescript-eslint/unbound-method
              Validators.required,
              // eslint-disable-next-line @typescript-eslint/unbound-method
              Validators.email,
            ]);
          } else {
            emailControl.clearValidators();
            emailControl.reset();
          }
          emailControl.updateValueAndValidity();
        }
      });

    // Initialize the subscription for Message toggle
    this._isSharingMessage$
      .pipe(takeUntil(this._destroy$))
      .subscribe((isSharing) => {
        const commentsControl = this.form.get('Comments');
        if (commentsControl) {
          if (isSharing) {
            commentsControl.setValidators([
              // eslint-disable-next-line @typescript-eslint/unbound-method
              Validators.required,
              Validators.maxLength(2000),
            ]);
          } else {
            commentsControl.clearValidators();
            commentsControl.reset();
          }
          commentsControl.updateValueAndValidity();
        }
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public sharingName(value: boolean): void {
    this._isSharingName$.next(value);
  }

  public sharingEmail(value: boolean): void {
    this._isSharingEmail$.next(value);
  }

  public sharingMessage(value: boolean): void {
    this._isSharingMessage$.next(value);
  }

  public closeModal(): void {
    this._modalRef.hide();
  }

  public submitPrayer(): void {
    if (this.form.invalid || this.form.disabled) {
      this.form.get('PrayerText')?.markAsTouched();
      this.form.get('PrayerText')?.markAsDirty();
      this.form.get('PrayerText')?.updateValueAndValidity();

      if (this._isSharingName$.value) {
        this.form.get('Name')?.markAsTouched();
        this.form.get('Name')?.markAsDirty();
        this.form.get('Name')?.updateValueAndValidity();
      }

      if (this._isSharingMessage$.value) {
        this.form.get('Comments')?.markAsTouched();
        this.form.get('Comments')?.markAsDirty();
        this.form.get('Comments')?.updateValueAndValidity();
      }

      if (this._isSharingEmail$.value) {
        this.form.get('Email')?.markAsTouched();
        this.form.get('Email')?.markAsDirty();
        this.form.get('Email')?.updateValueAndValidity();
      }
      return;
    }

    const dto: CreatePrayerDto = {
      PrayerText: this.form.value.PrayerText?.trim() ?? '',
      PrayerTitle: this.form.value.PrayerTitle?.trim() ?? '',
      IsSharingInfo: this._isSharingEmail$.value || this._isSharingName$.value,
      Name: this.form.value.Name?.trim() ?? '',
      Email: this.form.value.Email?.trim() ?? '',
      Comments: this.form.value.Comments?.trim() ?? '',
    };
    this.isSubmittingForm.set(true);
    this.form.disable();
    this._prayerService.sendPrayer(dto).subscribe({
      next: (response) => {
        void this._partyTime();
        console.log('Prayer Received', response);
        this.closeModal();
      },
      error: (error) => {
        // TODO: Set Toast Error Message and Log to Sentry
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 429:
              console.log('Rate Limit Hit!');
              break;
            default:
              console.log('Unknown Http Error', error);
          }
        } else {
          console.error('Error sending prayer', error);
        }
        this.form.enable();
        this.isSubmittingForm.set(false);
      },
      complete: () => {
        this.isSubmittingForm.set(false);
        this.form.enable();
        this._modalRef.hide();
      },
    });
  }

  private async _partyTime() {
    const { default: confetti } = await import('canvas-confetti');

    const count = 200;
    const defaults = {
      origin: { y: 0.7 },
    };

    function fire(particleRatio: number, opts: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void confetti({
        ...defaults,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    // Fire a series of confetti bursts with varying parameters
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }
}
