<div id="about" class="py-5 bg-white">
  <div class="container-xxl p-3 p-lg-5 text-start">
    <div class="col-xl-12">
      <div
        class="d-flex justify-content-center align-items-center column-gap-3 mb-3"
      >
        <img
          class="d-flex-inline"
          ngSrc="/img/disruption.png"
          alt="broken heart"
          height="85"
          width="85"
        />
        <h1 class="d-flex-inline text-center mt-2">Disruptions</h1>
      </div>
    </div>
    <div class="col-xl-12">
      <p class="fs-16px text-body text-opacity-50">
        It is easy to think that after we are matched with an expectant mother
        that we will share our lives with that child. We hope that this process
        goes as smoothly as possible. But in some cases, the expectant mother
        can choose to parent and that is her right. If she feels fit to parent,
        that is an absolutely amazing thing. But it does not come without
        heartache. We ask that as we share updates with you, that you keep in
        mind that sometimes things could change at a moments notice. While we
        hope that we never have to experience a disruption in our adoption
        journey, it is something that could happen and can be helpful for you to
        keep in mind as you help support us.
      </p>
    </div>
  </div>
</div>
