<div id="header" class="app-header navbar navbar-expand-lg p-0">
  <div class="container-xxl px-3 px-lg-5 d-flex align-items-center flex-1">
    <button
      type="button"
      class="navbar-mobile-toggler px-0 me-3"
      data-bs-toggle="collapse"
      data-bs-target="#navbarContent"
    >
      <fa-icon size="2x" [icon]="faBars" />
    </button>
    <a
      class="navbar-brand px-0 ms-0 justify-content-start"
      [routerLink]="['/']"
    >
      <span class="navbar-logo d-md-block d-none"></span>
      <b class="me-3px">Little</b>
      Junebug
    </a>
    <div
      class="collapse navbar-collapse ms-auto d-md-flex align-items-center"
      id="navbarContent"
    >
      <div class="navbar-nav ms-auto fw-500 d-md-flex justify-content-end">
        @for (item of menuItems$(); track item.label) {
          <div class="nav-item me-md-3 me-lg-2">
            <a class="nav-link text-body" [routerLink]="[item.path]">
              {{ item.label }}
            </a>
          </div>
        }
      </div>
    </div>
    <div class="ms-3">
      <a
        href="https://adopttogether.org/families/the-beveridges"
        target="_blank"
        class="btn btn-theme fw-bold rounded-pill px-3 py-2 text-nowrap"
      >
        Donate
        <fa-icon class="ms-2 opacity-5" [icon]="faArrowRight" />
      </a>
    </div>
  </div>
</div>
