<div class="error">
  <div class="error-code">404</div>
  <div class="error-content">
    <div class="error-message">We couldn't find it...</div>
    <div class="error-desc mb-4">
      The page you're looking for doesn't exist.
      <br />
      Perhaps, there pages will help find what you're looking for.
    </div>
    <div>
      <a class="btn btn-success px-3" [routerLink]="['/']">Go Home</a>
    </div>
  </div>
</div>
