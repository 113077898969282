@defer (hydrate never) {
  <div class="modal-header">
    <h4 class="modal-title flex-1">
      <fa-icon class="me-3" [icon]="faPrayingHands" />
      Send a Prayer
    </h4>
    <button
      type="button"
      class="align-self-end btn btn-icon"
      (click)="closeModal()"
    >
      <fa-icon class="fs-20px" [icon]="faXmark" />
    </button>
  </div>
  <div class="modal-body">
    <p class="mb-3">
      We would like to invite you to pray for this woman and her unborn child.
      We do not know who she is, what her circumstances are like or any
      information about her. This is your opportunity to let her know that she
      is loved and supported. After the adoption takes place we will present
      these prayers to the mother to help remind her that she will always be a
      mother and that there is a community far and wide to provide her with
      support.
    </p>
    <form [formGroup]="form">
      <div class="form-floating mb-3">
        <textarea
          type="text"
          class="form-control fs-15px"
          id="prayer"
          style="height: 10rem; resize: none"
          formControlName="PrayerText"
          [makeValidator]="true"
        ></textarea>
        <label for="prayer" class="d-flex align-items-start fs-13px">
          Prayer
        </label>
      </div>
      <make-optional-field-container
        label="I want to share my name with the birth mother"
        [isToggledOn]="(isSharingName$ | async) ?? false"
        (isToggledOnChange)="sharingName($event)"
      >
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control fs-15px"
            id="name"
            autocomplete="name"
            formControlName="Name"
            [makeValidator]="true"
          />
          <label for="name" class="d-flex align-items-start fs-13px">
            Name
          </label>
        </div>
      </make-optional-field-container>
      <hr />
      <make-optional-field-container
        label="I want to share a message with the birth mother"
        [isToggledOn]="(isSharingMessage$ | async) ?? false"
        (isToggledOnChange)="sharingMessage($event)"
      >
        <div class="form-floating mb-3">
          <textarea
            type="text"
            class="form-control fs-15px"
            id="prayerMessage"
            style="height: 7rem; resize: none"
            formControlName="Comments"
            [makeValidator]="true"
          ></textarea>
          <label for="prayerMessage" class="d-flex align-items-start fs-13px">
            Message
          </label>
        </div>
      </make-optional-field-container>
      <hr />
      <make-optional-field-container
        label="I want to share my email with Joe and Joanna"
        [isToggledOn]="(isSharingEmail$ | async) ?? false"
        (isToggledOnChange)="sharingEmail($event)"
      >
        <div class="form-floating mb-3">
          <input
            type="email"
            class="form-control fs-15px"
            id="email"
            formControlName="Email"
            [makeValidator]="true"
          />
          <label for="email" class="d-flex align-items-start fs-13px">
            Email address
          </label>
        </div>
      </make-optional-field-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-white" (click)="closeModal()">
      Close
    </button>
    <button type="submit" class="btn btn-success" (click)="submitPrayer()">
      @if (!isSubmittingForm()) {
        <fa-icon class="me-1" [icon]="faPaperPlane" />
      } @else {
        <fa-icon class="me-1" animation="spin" [icon]="faSpinner" />
      }
      Send
    </button>
  </div>
}
