import { Component } from '@angular/core';

@Component({
  selector: 'app-adoption-process',
  imports: [],
  templateUrl: './adoption-process.component.html',
  styleUrl: './adoption-process.component.css',
  standalone: true,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AdoptionProcessComponent {}
