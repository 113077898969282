import { Component } from '@angular/core';

@Component({
  selector: 'app-hero',
  imports: [],
  templateUrl: './hero.component.html',
  standalone: true,
  styleUrl: './hero.component.scss',
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class HeroComponent {}
